import { I18n } from "i18n-js";

import es from "./es";
import pt from "./pt";
import en from "./en";
import { getLanguage } from "../shared/helper";

const i18n = new I18n({
  en,
  es,
  pt,
});

i18n.enableFallback = true;

i18n.defaultLocale = getLanguage();
i18n.locale = getLanguage();

export default i18n;
