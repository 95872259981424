const pt = {
  app: {
    Buttons: {
      Contacts: "Contato",
      Zones: "Exibir áreas de cobertura",
    },
    Language: {
      es: "Espanhol",
      en: "Inglês",
      pt: "Português",
    },
    Navbar: {
      Home: "Começo",
      Product: "Produtos",
      Cases: "Histórias de sucesso",
      Us: "Nós",
      Contact_Us: "Entre em contato conosco",
      Client_Access: "ACESSO DO CLIENTE",
    },
    LandingFirstPage: {
      First_Title_Part: "Dê identidade\n aos seus ",
      Second_Title_Part: "ativos\n de campo.",
      First_Subtitle_Part: "Gerencie e controle seus grãos",
      Second_Subtitle_Part: "armazenados com nossa ",
      Third_Subtitle_Part: "plataforma.",
      first_button: "O que é IOF?",
    },
    LandingSecondPage: {
      first_button: "SAIBA MAIS",
    },
    LandingThirdPage: {
      Active_Tons_Title_First: "Toneladas ",
      Active_Tons_Title_Second: "Ativos",
      Countrysides: "Fazendas",
      How: "COMO?",
      Producers: "Produtores",
      What_Do_We_Do: "O QUE FAZEMOS?",
      What_Do_We_Do_First_Part: "Oferecemos ",
      What_Do_We_Do_Second_Part: "identidade digital ",
      What_Do_We_Do_Third_Part: "em seus grãos armazenados no ",
      What_Do_We_Do_Fourth_Part: "campo para obter...",
      What_Do_We_Do_Fifth_Part:
        "Por meio do SiloReal, conectamos seu estoque em campo a um ecossistema que fornece ",
      What_Do_We_Do_Sixth_Part: "novas oportunidades de negócios.",
    },
    LandingFourthPage: {
      Cash: "Cash",
      Companies_Crypto: "Empresas\n criptografia",
      Control: "Control",
      Existence: "EXISTENCE",
      Identity: "IDENTIDADE",
      Insurance: "Seguro",
      Insurers: "Seguradoras",
      Financial_Institutions: "Instituições\n financeiras",
      Link: "saiba mais",
      Owner: "Proprietário",
      Score: "Score",
      Scoring: "Scoring",
      Security_companies: "Segurança",
      State: "ESTADO",
      Stockpiles: "Armazenamento",
      Token: "Token",
    },
    LandingFifthPage: {
      Benefits: "BENEFÍCIOS",
      BenefistOne: {
        Title: "Plataforma de gerenciamento",
        Subtitle:
          "Permite que você saiba em tempo real quanto você tem, onde\n você tem e como está.",
        First_Item: "Mantenha seu estoque atualizado a partir do campo.",
        Second_Item:
          "Unificar todas as informações de seus silos em um só lugar.",
        Third_Item: "Tomar decisões remotamente.",
      },
      BenefistTwo: {
        Title: "Alertas de qualidade e segurança",
        Subtitle:
          "Receba alertas instantâneos sobre o que está acontecendo no campo, para que você possa agir o mais rápido possível.",
        First_Item: "Saiba o que está acontecendo dentro do silo bolsa.",
        Second_Item:
          "Deixe que as informações sobre o que está acontecendo cheguem até você.",
        Third_Item: "Evite perda de qualidade, roubo e vandalismo.",
      },
      BenefistThird: {
        Title: "extensão de sublimites",
        Subtitle:
          "Permite que você faça o seguro de grãos armazenados contra vandalismo, roubo, incêndio, granizo e intempéries.",
        First_Item: "Faça seguro de ativos que antes não podiam ser segurados.",
        Second_Item: "Apólices mais econômicas.",
        Third_Item: "Expanded sub-limits.",
      },
      BenefistFourth: {
        Title: "Acesso à liquidez",
        Subtitle:
          "Acesse a liquidez de diferentes maneiras usando seus ativos como garantia",
        First_Item: "Garantia de seus grãos a baixo custo.",
        Second_Item: "Tokenise your beans by accessing immediate liquidity.",
        Third_Item: "Estenda sua linha de crédito.",
      },
    },
    LandingLayerOfValuePage: {
      Access: "Acesso à liquidez",
      Identity: "Identidad",
      Layer: "Camada de valor",
      Quality: "Qualidade e segurança",
      Traceability: "Rastreabilidade e gerenciamento",
    },
    LandingContactPage: {
      Commercial_Director: "Diretor Comercial",
      COO: "COO",
      Country_Manager: "Diretor do país",
      Main_Title: "Entre em contato com a nossa equipe",
      Support: "Suporte",
    },
    ProductPage: {
      Id: "ID",
      Metrix: "Metrix",
      Modal: "Áreas de cobertura",
      Modal_First_Title: "Província de Buenos Aires",
      Modal_Second_Title: "Norte da Argentina",
      Real: "Real",
      Silo: "Silo",
      Silo_Id: {
        title:
          "Ele permite que cada saco de silo tenha sua própria\n identidade, tornando-se único, visível e rastreável.",
        First_Item:
          "Documentação da montagem do silobag\n (prova inicial de existência).",
        Second_Item:
          "ID exclusivo de cada silo-bag georreferenciado com\n o Field Merchandise Certificate.",
        Third_Item:
          "Digitalização das informações na origem para ter\n o prazo de validade do silobag em um único local.",
      },
      Silo_Metrix: {
        title:
          "Permite que você saiba como seus grãos estão armazenados\n no campo e receba alertas em tempo real.",
        First_Item:
          "Telemetria de silo-bag em tempo real com sensores\n de temperatura, umidade, CO2 e movimento.",
        Second_Item:
          "Prevenir perdas de qualidade, roubo e vandalismo\n por meio de alertas instantâneos.",
        Third_Item:
          "Otimiza o gerenciamento de logística por meio do TAS\n (tempo de armazenamento seguro).",
      },
      Silo_View: {
        title:
          "Permite que você veja o SiloID remotamente\n e saiba que seu silobag está no campo.",
        First_Item:
          "Teste de existência recorrente de cada SiloID\n sem precisar ir ao campo",
        Second_Item: "Monitoramento do SiloID por meio de imagens de satélite.",
        Third_Item:
          "Possibilidade de tornar o ativo visível para terceiros,\n fornecendo acesso a soluções de segurança e liquidez.",
      },
      Track: "Track",
      View: "View",
    },
    UsPage: {
      Main_Title_First: "A passionate ",
      Main_Title_Second: "equipe multidisciplinar ",
      Main_Title_Third: "que\n combina conhecimento de ",
      Main_Title_Fourth:
        "agronegócio, tecnologia,\n empreendedorismo e securitização.",
      First_Box_Title: "Quem somos",
      First_Box_Content:
        "A Identity On Field (IOF) é uma startup latino-americana de ag-fin-tech\n que busca resolver problemas\n do mundo real de forma escalável.",
      Second_Box_Title: "Missão",
      Second_Box_Content_One:
        "Nossa missão é dar identidade digital aos ativos no campo para resolver problemas de rastreabilidade,\n segurança e acesso à liquidez. ",
      Second_Box_Content_Two:
        "Por meio de tecnologias (IoT, imagens de satélite e blockchain), criamos uma ponte digital que conecta\n ativos de campo de alto valor a um ecossistema de compradores, seguradoras, instituições financeiras\n e outros.",
      Third_Box_Title: "Conheça alguns de nossos clientes",
      Third_Box_Content:
        "Essas empresas dão identidade a seus grãos com a SiloID.",
      Team: "Equipe",
    },
  },
};

export default pt;
