import { createContext, useEffect, useState } from "react";

const initialState = {
  text: "",
  type: "success",
  timeout: 100,
  setAlert: (text: string, type: "success" | "danger") => {},
  closeAlert: () => {},
};

const AlertContext = createContext(initialState);

/**
 * Alert context para poder utilizar alertas globalmente en la aplicacion
 * @param children Elementos hijos para poder utilizar este provider
 * @returns Context.Provider
 */
export const AlertProvider = ({ children }: any) => {
  const [text, setText] = useState("");
  const [type, setType] = useState("");
  const [isShowing, setIsShowing] = useState(false);
  const [timeout, setTimeout] = useState(100);

  const setAlert = (text: string, type: "success" | "danger") => {
    setText(text);
    setType(type);
    setIsShowing(true);
  };

  useEffect(() => {
    if (timeout <= 0) {
      closeAlert();
    }
  }, [timeout]);

  useEffect(() => {
    if (isShowing) {
      const interval = setInterval(() => {
        setTimeout((oldValue) => {
          const newValue = oldValue - 2;
          if (newValue === 0) {
            clearInterval(interval);
          }
          return newValue;
        });
      }, 200);
      return () => clearInterval(interval);
    }
  }, [isShowing]);

  const closeAlert = () => {
    setIsShowing(false);
    setText("");
    setType("");
    setTimeout(100);
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        type,
        timeout,
        setAlert,
        closeAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
