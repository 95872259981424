import React from "react";

const NoConnectionPage = () => {
  return (
    <React.Fragment>
      <div style={{ display: "flex", width: "100%", height: "100%" }}>
        <h2 style={{ margin: "auto" }}>NO INTERNET CONNECTION</h2>
      </div>
    </React.Fragment>
  );
};

export default NoConnectionPage;
