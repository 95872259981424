import React, { memo, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import ArrowBlueIcon from "../../assets/img/arrow-blue.svg";
import ArrowGreenIcon from "../../assets/img/arrow-green.svg";
import ArrowOrangeIcon from "../../assets/img/arrow-orange.svg";

import PlaystoreEs from "../../assets/img/landing-first-page/playstore-es.png";
import PlaystoreBr from "../../assets/img/landing-first-page/playstore-br.png";
import PlaystoreEn from "../../assets/img/landing-first-page/playstore-en.png";

import SiloId from "../../assets/img/landing-product/silo-id.png";
import SiloIdMobile from "../../assets/img/landing-product/silo-id-responsive.png";
import SiloMetrix from "../../assets/img/landing-product/silo-metrix.png";
import SiloView from "../../assets/img/landing-product/silo-view.png";

import SiloIDIcon from "../../assets/img/landing-fourth-page/siloid.svg";
import SiloViewIcon from "../../assets/img/landing-fourth-page/satelite.svg";
import SiloMetrixIcon from "../../assets/img/landing-fourth-page/silobolsa.svg";

import GreenVector from "../../assets/img/landing-product/green-vector.png";
import BlueVector from "../../assets/img/landing-product/blue-vector.png";
import OrangeVector from "../../assets/img/landing-product/orange-vector.png";

import { ProductSectionProps, tplotOptions } from "../../shared/types";

import "../../assets/styles/landingStyles.css";
import i18n from "../../translate/Language";
import useImagePreloader from "../../hooks/useImagePreloader";
import { CustomButton } from "../customButton";
import ProductModal from "../modals/ProductModal";

const playstore: tplotOptions = {
  es: PlaystoreEs,
  pt: PlaystoreBr,
  en: PlaystoreEn,
};

const preloadSrcList: string[] = [
  ArrowBlueIcon,
  ArrowGreenIcon,
  ArrowOrangeIcon,
  BlueVector,
  GreenVector,
  OrangeVector,
  PlaystoreEs,
  PlaystoreBr,
  PlaystoreEn,
  SiloId,
  SiloIdMobile,
  SiloIDIcon,
  SiloMetrix,
  SiloMetrixIcon,
  SiloView,
  SiloViewIcon,
];

const ProductComponent = memo(
  ({ isMobile, item, setOpenModal, scroll, tagText }: any) => {
    return (
      <React.Fragment>
        <Grid
          item
          xs={16}
          md={6}
          lg={8}
          sx={{ whiteSpace: isMobile ? "" : "pre-line", textAlign: "left" }}
        >
          <Typography
            className={"custom-landing-tag-product"}
            variant="h6"
            gutterBottom
            sx={{ fontWeight: 500, textAlign: "right" }}
          >
            <div className={"black-color"}>
              {i18n.t("app.Navbar.Product")}
              <span className="fw-bold-700">{` ${tagText}`}</span>
            </div>
          </Typography>

          <div className={"product-container"}>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              gutterBottom
              sx={{
                fontWeight: 500,
                mb: 2,
              }}
            >
              <img
                className={`img-product-${item?.title}`}
                src={item?.iconImg}
                alt={item?.id}
              />
              {i18n.t("app.ProductPage.Silo")}
              <span className={`${item?.colorTitle} fw-bold-700`}>
                {item?.title}
              </span>
            </Typography>
            <Typography
              className="white-color fs-18"
              gutterBottom
              sx={{
                fontWeight: 600,
                mb: 2,
              }}
            >
              {item?.subtitle}
            </Typography>
            {item?.items?.map((i: any, index: number) => (
              <Typography
                className="card-benefits-items fs-16"
                key={index}
                gutterBottom
                sx={{
                  fontWeight: 500,
                  mb: isMobile ? 5 : i?.mb !== undefined ? i?.mb : 4,
                  textAlign: "left",
                }}
              >
                <img
                  className="card-product-item"
                  src={item?.arrow}
                  alt={"vector-line"}
                />
                <span>{i?.title}</span>
              </Typography>
            ))}
            <Grid
              container
              spacing={2}
              columns={16}
              sx={{ display: { xs: "flex" }, mt: 5 }}
            >
              {item?.withPlaystore && (
                <Grid item>
                  <img
                    className={"playstore-product-icon"}
                    src={playstore[i18n.locale]}
                    alt={"playstore"}
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.iof_mobile&authuser=1&hl=es_419",
                        "_blank"
                      )
                    }
                  />
                </Grid>
              )}

              <Grid item>
                <CustomButton
                  id={`custom-button-${item?.title}`}
                  className={`${
                    item?.withZone && "default-product-button-zone"
                  } default-product-button custom-button-${
                    item?.colorButton
                  } black-color fs-16 fw-bold-600`}
                  label={i18n.t("app.Buttons.Contacts")}
                  type="SIMPLE"
                  onClick={() => {
                    scroll && scroll("#contact-page");
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
);

/**
 * Product section
 * @param props Props for ProductSectionProps
 * @returns JSX.Element
 */
const ProductSection = (props: ProductSectionProps) => {
  const { className, id, isMobile, scroll } = props;
  const [open, setOpenModal] = useState<boolean>(false);
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  const Products = [
    {
      id: "silo-id",
      arrow: ArrowGreenIcon,
      background: "cp-background-silo-id",
      colorTitle: "main-color",
      colorButton: "green",
      img: isMobile ? SiloIdMobile : SiloId,
      iconImg: SiloIDIcon,
      title: i18n.t("app.ProductPage.Id"),
      subtitle: i18n.t("app.ProductPage.Silo_Id.title"),
      items: [
        {
          title: i18n.t("app.ProductPage.Silo_Id.First_Item"),
        },
        {
          title: i18n.t("app.ProductPage.Silo_Id.Second_Item"),
        },
        {
          title: i18n.t("app.ProductPage.Silo_Id.Third_Item"),
        },
      ],
      vector: GreenVector,
      withPlaystore: true,
    },
    {
      id: "silo-view",
      arrow: ArrowBlueIcon,
      background: "cp-background-silo-view",
      colorTitle: "blue-color",
      colorButton: "blue",
      img: SiloView,
      iconImg: SiloViewIcon,
      title: i18n.t("app.ProductPage.View"),
      subtitle: i18n.t("app.ProductPage.Silo_View.title"),
      items: [
        {
          title: i18n.t("app.ProductPage.Silo_View.First_Item"),
        },
        {
          title: i18n.t("app.ProductPage.Silo_View.Second_Item"),
          mb: 1,
        },
        {
          title: i18n.t("app.ProductPage.Silo_View.Third_Item"),
        },
      ],
      vector: BlueVector,
    },
    {
      id: "silo-metrix",
      arrow: ArrowOrangeIcon,
      background: "cp-background-silo-metrix",
      colorTitle: "orange-color",
      colorButton: "orange",
      img: SiloMetrix,
      iconImg: SiloMetrixIcon,
      title: i18n.t("app.ProductPage.Metrix"),
      subtitle: i18n.t("app.ProductPage.Silo_Metrix.title"),
      items: [
        {
          title: i18n.t("app.ProductPage.Silo_Metrix.First_Item"),
        },
        {
          title: i18n.t("app.ProductPage.Silo_Metrix.Second_Item"),
        },
        {
          title: i18n.t("app.ProductPage.Silo_Metrix.Third_Item"),
        },
      ],
      vector: OrangeVector,
      withZone: true,
    },
  ];

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Grid
      key={id}
      container
      columns={isMobile ? 0 : 16}
      textAlign={isMobile ? "left" : "center"}
      className={`${className} white-color`}
    >
      {Products?.map((p, index: number) => (
        <Grid
          id={p?.id}
          key={index}
          container
          spacing={isMobile ? 0 : 2}
          columns={isMobile ? 0 : 16}
          className={`${p?.background} first-landing`}
          sx={{
            position: "relative",
            height: isMobile
              ? index === 0
                ? "fit-content"
                : "135vh"
              : "100vh",
          }}
        >
          <ProductComponent
            isMobile={isMobile}
            item={p}
            setOpenModal={setOpenModal}
            scroll={scroll}
            tagText={`${i18n.t("app.ProductPage.Silo")}${i18n.t(
              "app.ProductPage.Real"
            )}`}
          />
          <Grid item xs={16} md={10} lg={8}>
            <img
              className={`product-main-img-${p?.title}`}
              src={p.img}
              alt={"ben-img"}
            />
            <img
              className="product-landing-vector"
              src={p?.vector}
              alt={"background-img-vector"}
            />
          </Grid>
        </Grid>
      ))}
      <ProductModal open={open} handleClose={handleClose} isMobile={isMobile} />
    </Grid>
  );
};

export default ProductSection;
