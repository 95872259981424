import Box from "@mui/material/Box";
import { PreLoadImgProps } from "../../shared/types";

/**
 * Pre Load Img
 * @param props Props for PreLoadImgProps
 * @returns JSX.Element
 */
const PreLoadImgComponent = (props: PreLoadImgProps) => {
  const { className } = props;

  return (
    <Box
      className={className}
      sx={{
        display: "flex",
        height: "100vh",
      }}
    ></Box>
  );
};

export default PreLoadImgComponent;
