import React from "react";
import useImagePreloader from "../../hooks/useImagePreloader";
import vectorLine from "../../assets/img/landing-contact-page/vectorLine.svg";

import { UsPageProps } from "../../shared/types";
import { Grid, Typography } from "@mui/material";
import FooterContactComponent from "../../components/base/footerContact/FooterContactComponent";
import { UsSection } from "../../components/us";
import i18n from "../../translate/Language";
import { PreLoadImgComponent } from "../../components/preLoadImg";

const preloadSrcList: string[] = [vectorLine];

/**
 * Main Page with set of component to build us page
 * @param props Props for UsPageProps
 * @returns JSX.Element
 */
const UsPage = (props: UsPageProps) => {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  const { isMobile } = props;

  const className = "main-page-container ";

  if (!imagesPreloaded) {
    return <PreLoadImgComponent className={`${className} landing`} />;
  }

  return (
    <React.Fragment>
      <Grid container textAlign={"center"}>
        <Typography
          className={`team-title
          } f-text-outline black-color-2 us-page-team`}
          component={"div"}
        >
          <Grid item xs={16} lg={16}>
            {i18n.t("app.UsPage.Team")}
          </Grid>
          <Grid item xs={16} lg={16} sx={{ mt: { xs: -20.2, sm: -26.2 } }}>
            {i18n.t("app.UsPage.Team")}
          </Grid>
        </Typography>
      </Grid>
      <UsSection
        id={"us-section"}
        isMobile={isMobile}
        className={`${className} landing-product`}
      />
      <FooterContactComponent
        className={`${className} landing `}
        fromProduct
        id={"contact-page"}
        isMobile={isMobile}
      />
    </React.Fragment>
  );
};

export default UsPage;
