import React from "react";
import useImagePreloader from "../../hooks/useImagePreloader";
import vectorLine from "../../assets/img/landing-contact-page/vectorLine.svg";

import { ProductPageProps } from "../../shared/types";
import FooterContactComponent from "../../components/base/footerContact/FooterContactComponent";
import { ProductSection } from "../../components/product";
import { PreLoadImgComponent } from "../../components/preLoadImg";

const preloadSrcList: string[] = [vectorLine];

/**
 * Main Page with set of component to build product page
 * @param props Props for ProductPageProps
 * @returns JSX.Element
 */
const ProductPage = (props: ProductPageProps) => {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  const { isMobile, scroll } = props;

  const className = "main-page-container ";

  if (!imagesPreloaded) {
    return <PreLoadImgComponent className={`${className} landing`} />;
  }

  return (
    <React.Fragment>
      <ProductSection
        id={"product-section"}
        isMobile={isMobile}
        className={"landing-product"}
        scroll={scroll}
      />
      <FooterContactComponent
        className={`${className} landing `}
        fromProduct
        id={"contact-page"}
        isMobile={isMobile}
      />
    </React.Fragment>
  );
};

export default ProductPage;
