import React from "react";

import { AlertProvider } from "./core/routes/AlertProvider";
import { RouterConfig } from "./core/routes/RouterConfig";

import "./assets/styles/mainStyles.css";
import "./assets/styles/fonts.css";
import "./assets/styles/colors.css";

function App() {
  return (
    <React.Suspense fallback="Cargando">
      <AlertProvider>
        <RouterConfig />
      </AlertProvider>
    </React.Suspense>
  );
}

export default App;
