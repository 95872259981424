import { Button, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React from "react";
import { CustomLinkProps } from "../../shared/types";

const CustomButton = (props: CustomLinkProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const {
    label,
    type,
    items,
    style,
    id,
    startAdornment,
    className,
    variant,
    title,
    disabled,
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    props.onClick && props.onClick(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const FinalClassName = () => {
    let classname = `custom-link ${className ? className : "bg-white "}`;
    if (type === "SELECT") classname += "custom-link-select";
    return classname;
  };

  return (
    <>
      <Button
        variant={variant}
        className={FinalClassName()}
        id={`link-button-${id}`}
        aria-controls={isOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        title={title}
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleClick}
        disabled={disabled}
        style={style}
      >
        {startAdornment && <AddIcon />}
        <span
          style={
            title
              ? {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }
              : {}
          }
        >
          {label}
        </span>
        {type === "SELECT" && <ArrowDropDownIcon />}
      </Button>
      {type === "SELECT" && (
        <Menu
          id={`link-button-${id}`}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {items?.map((elem: any, index: number) => {
            return (
              <MenuItem
                key={`custom-button-${index}`}
                value={elem.value}
                onClick={() => {
                  elem.onClick();
                  handleClose();
                }}
                disabled={elem.disabled}
              >
                {elem.label}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

export default CustomButton;
