const es = {
  app: {
    Buttons: {
      Contacts: "Contactanos",
      Zones: "Ver zonas de cobertura",
    },
    Language: {
      es: "Español",
      en: "Inglés",
      pt: "Portugués",
    },
    Navbar: {
      Home: "Inicio",
      Product: "Productos",
      Cases: "Casos de éxito",
      Us: "Nosotros",
      Contact_Us: "Contactanos",
      Client_Access: "ACCESO CLIENTES",
    },
    LandingFirstPage: {
      First_Title_Part: "Dale identidad\n a tus ",
      Second_Title_Part: "activos\n en campo.",
      First_Subtitle_Part: "Gestioná y monitoreá tus granos",
      Second_Subtitle_Part: "almacenados con nuestra ",
      Third_Subtitle_Part: "plataforma.",
      first_button: "¿Qué es IOF?",
    },
    LandingSecondPage: {
      first_button: "Conocer más",
    },
    LandingThirdPage: {
      Active_Tons_Title_First: "Toneladas ",
      Active_Tons_Title_Second: "Activas",
      Countrysides: "Establecimientos",
      How: "¿CÓMO?",
      Producers: "Productores",
      What_Do_We_Do: "¿QUÉ HACEMOS?",
      What_Do_We_Do_First_Part: "Le damos ",
      What_Do_We_Do_Second_Part: "identidad digital ",
      What_Do_We_Do_Third_Part: "a tus granos almacenados en ",
      What_Do_We_Do_Fourth_Part: "campo para obtener...",
      What_Do_We_Do_Fifth_Part:
        "A través de SiloReal conectamos tu stock en campo a un ecosistema que brinda ",
      What_Do_We_Do_Sixth_Part: "nuevas oportunidades de negocio.",
    },
    LandingFourthPage: {
      Cash: "Cash",
      Companies_Crypto: "Empresas\n cripto",
      Control: "Control",
      Existence: "EXISTENCIA",
      Identity: "IDENTIDAD",
      Insurance: "Seguro",
      Insurers: "Aseguradoras",
      Financial_Institutions: "Entidades\n financieras",
      Link: "conocer más",
      Owner: "Propietario",
      Score: "Score",
      Scoring: "Scoring",
      Security_companies: "Seguridad",
      State: "ESTADO",
      Stockpiles: "Acopios",
      Token: "Token",
    },
    LandingFifthPage: {
      Benefits: "BENEFICIOS",
      BenefistOne: {
        Title: "Plataforma de gestión",
        Subtitle:
          "Permite saber en tiempo real cuánto tenés, dónde\n lo tenés y cómo está.",
        First_Item: "Mantené tu stock actualizado desde el campo.",
        Second_Item:
          "Unificá toda la información de tus silos en un mismo lugar.",
        Third_Item: "Tomá decisiones de forma remota.",
      },
      BenefistTwo: {
        Title: "Alertas de calidad & seguridad",
        Subtitle:
          "Recibí alertas instantáneas de lo que sucede en el campo, para poder tomar acción lo antes posible.",
        First_Item: "Sabé lo que sucede dentro del silobolsa.",
        Second_Item: "Que la información de lo que está pasando venga a vos.",
        Third_Item: "Evitá perdidas de calidad, robo y vandalismo.",
      },
      BenefistThird: {
        Title: "Seguros más accesibles",
        Subtitle:
          "Permite asegurar los granos almacenados frente a vandalismo, robo, incendio, granizo y clima.",
        First_Item: "Asegurá activos que antes no eran asegurables.",
        Second_Item: "Pólizas más económicas.",
        Third_Item: "Ampliación de sub límites.",
      },
      BenefistFourth: {
        Title: "Acceso a liquidez",
        Subtitle:
          "Accedé a liquidez de diferentes formas usando tus activos como colateral",
        First_Item: "Warranteá tus granos con bajo costo.",
        Second_Item: "Tokenizá tus granos accediendo a liquidez inmediata.",
        Third_Item: "Ampliá tu línea de crédito.",
      },
    },
    LandingLayerOfValuePage: {
      Access: "Acceso a Liquidez",
      Identity: "Identidad",
      Layer: "Capas de valor",
      Quality: "Calidad & Seguridad",
      Traceability: "Trazabilidad & Gestión",
    },
    LandingContactPage: {
      Commercial_Director: "Director Comercial",
      COO: "COO",
      Country_Manager: "Director nacional",
      Main_Title: "Contactate con nuestro equipo",
      Support: "Soporte",
    },
    ProductPage: {
      Id: "ID",
      Metrix: "Metrix",
      Modal: "Zonas de cobertura",
      Modal_First_Title: "Provincia de Bs. As.",
      Modal_Second_Title: "Norte de Argentina",
      Real: "Real",
      Silo: "Silo",
      Silo_Id: {
        title:
          "Permite que cada silobolsa tenga su identidad,\n volviéndose único, visible y trazable.",
        First_Item:
          "Documentación del armado del silobolsa\n (prueba de existencia inicial).",
        Second_Item:
          "ID único de cada silobolsa georreferenciado con\n Certificado de Mercadería en Campo.",
        Third_Item:
          "Digitalización de la información en origen para tener\n la vida útil del silobolsa en un solo lugar.",
      },
      Silo_Metrix: {
        title:
          "Permite saber cómo están tus granos almacenados\n en campo y recibir alertas en tiempo real.",
        First_Item:
          "Telemetría en silobolsas en tiempo real con sensores\n de temperatura, humedad, CO2 y movimiento.",
        Second_Item:
          "Evita pérdidas de calidad, robo y vandalismo\n mediante alertas instantáneas.",
        Third_Item:
          "Optimiza la gestión de logística a través del TAS\n (tiempo de almacenamiento seguro).",
      },
      Silo_View: {
        title:
          "Permite ver el SiloID en forma remota y\n saber que tu silobolsa está.",
        First_Item:
          "Prueba de existencia recurrente de cada SiloID\n sin tener que ir al campo.",
        Second_Item: "Monitoreo del SiloID a través de imágenes satelitales.",
        Third_Item:
          "Posibilidad de visibilizar el activo a terceros,\n brindando acceso a soluciones de seguridad y liquidez.",
      },
      Track: "Track",
      View: "View",
    },
    UsPage: {
      Main_Title_First: "Un apasionado ",
      Main_Title_Second: "equipo multidisciplinario ",
      Main_Title_Third: "que\n combina conocimientos de ",
      Main_Title_Fourth:
        "agro, tecnología,\n emprendedurismo y securitización.",
      First_Box_Title: "Quienes somos",
      First_Box_Content:
        "Identity On Field (IOF) es una startup ag-fin-tech\n latinoamericana que busca resolver problemas\n reales del mundo real de manera escalable.",
      Second_Box_Title: "Misión",
      Second_Box_Content_One:
        "Nuestra misión es darle identidad digital a los activos en campo para resolver problemas de trazabilidad,\n seguridad y acceso a liquidez. ",
      Second_Box_Content_Two:
        "A través de las tecnologías (IoT, imágenes satelitales y blockchain) creamos un puente digital que conecta\n activos en campo de gran valor con un ecosistema de compradores, aseguradoras, instituciones financieras,\n entre otros.",

      Third_Box_Title: "Conocé algunos de nuestros clientes",
      Third_Box_Content:
        "Estas empresas le dan identidad a sus granos con SiloID.",
      Team: "Equipo",
    },
  },
};
export default es;
