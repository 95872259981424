// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.white-color {
    color: #FFFFFF !important;
}
.white-color-2 {
    color: #ffffffcc !important;
}
.light-white-color {
    color: #FFFFFF87;
}
.gray-color {
    color: #B5B5B5;
}
.black-color {
    color: #1A1A1A !important;
}

.black-color-2 {
    color: #0D0D0D !important;
}
.blue-color {
    color: #65B7FF !important;
}
.main-color {
    color: #9DFF00 !important;
}
.orange-color {
    color: #FFA000 !important;
}

.background-main-color {
    background-color: #9DFF00;
}
.background-white-color {
    background-color: #FFFFFF;
}
.background-bold-gray-color {
    background-color: #232323;
}

.white-border {
    border: 1px solid #FFFFFF;
    border-radius: 5px;
}
.main-color-border {
    border: 1px solid #9DFF00;
}
.main-color-border-second {
    border: 3.345px solid #9DFF00;
    border-radius: 4.46px;
}
.main-blue-border {
    border: 1px solid #65B7FF;
}
.main-orange-border {
    border: 1px solid #FFA000;
}

.custom-button-green {
    background-color: #9DFF00 !important;
}

.custom-button-blue {
    background-color: #65B7FF !important;
}

.custom-button-orange {
    background-color: #FFA000 !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/colors.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,6BAA6B;IAC7B,qBAAqB;AACzB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".white-color {\n    color: #FFFFFF !important;\n}\n.white-color-2 {\n    color: #ffffffcc !important;\n}\n.light-white-color {\n    color: #FFFFFF87;\n}\n.gray-color {\n    color: #B5B5B5;\n}\n.black-color {\n    color: #1A1A1A !important;\n}\n\n.black-color-2 {\n    color: #0D0D0D !important;\n}\n.blue-color {\n    color: #65B7FF !important;\n}\n.main-color {\n    color: #9DFF00 !important;\n}\n.orange-color {\n    color: #FFA000 !important;\n}\n\n.background-main-color {\n    background-color: #9DFF00;\n}\n.background-white-color {\n    background-color: #FFFFFF;\n}\n.background-bold-gray-color {\n    background-color: #232323;\n}\n\n.white-border {\n    border: 1px solid #FFFFFF;\n    border-radius: 5px;\n}\n.main-color-border {\n    border: 1px solid #9DFF00;\n}\n.main-color-border-second {\n    border: 3.345px solid #9DFF00;\n    border-radius: 4.46px;\n}\n.main-blue-border {\n    border: 1px solid #65B7FF;\n}\n.main-orange-border {\n    border: 1px solid #FFA000;\n}\n\n.custom-button-green {\n    background-color: #9DFF00 !important;\n}\n\n.custom-button-blue {\n    background-color: #65B7FF !important;\n}\n\n.custom-button-orange {\n    background-color: #FFA000 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
