const en = {
  app: {
    Buttons: {
      Contacts: "Contact us",
      Zones: "View coverage areas",
    },
    Language: {
      es: "Spanish",
      en: "English",
      pt: "Portuguese",
    },
    Navbar: {
      Home: "Home",
      Product: "Products",
      Cases: "Successful stories",
      Us: "About us",
      Contact_Us: "Contact us",
      Client_Access: "CUSTOMER LOGIN",
    },
    LandingFirstPage: {
      First_Title_Part: "Give identity\n to your ",
      Second_Title_Part: "assets\n in the field.",
      First_Subtitle_Part: "Manage and monitor your grains",
      Second_Subtitle_Part: "stored with our ",
      Third_Subtitle_Part: "platform.",
      first_button: "What is IOF?",
    },
    LandingSecondPage: {
      first_button: "LEARN MORE",
    },
    LandingThirdPage: {
      Active_Tons_Title_First: "Tons ",
      Active_Tons_Title_Second: "Actives",
      Countrysides: "Countrysides",
      How: "HOW?",
      Producers: "Producers",
      What_Do_We_Do: "WHAT DO WE DO?",
      What_Do_We_Do_First_Part: "We give ",
      What_Do_We_Do_Second_Part: "digital identity ",
      What_Do_We_Do_Third_Part: "to your grains stored in the ",
      What_Do_We_Do_Fourth_Part: "field to obtain...",
      What_Do_We_Do_Fifth_Part:
        "Through SiloReal we connect your field stock to an ecosystem that provides ",
      What_Do_We_Do_Sixth_Part: "new business opportunities.",
    },
    LandingFourthPage: {
      Cash: "Cash",
      Companies_Crypto: "Crypto\n companies",
      Control: "Control",
      Existence: "EXISTENCE",
      Identity: "IDENTITY",
      Insurance: "Seguro",
      Insurers: "Insurers",
      Financial_Institutions: "Financial\n institutions",
      Link: "learn more",
      Owner: "Farmer",
      Real: "Real",
      Score: "Score",
      Scoring: "Scoring",
      Security_companies: "Security",
      State: "STATE",
      Stockpiles: "Stockpiles",
      Token: "Token",
    },
    LandingFifthPage: {
      Benefits: "BENEFITS",
      BenefistOne: {
        Title: "Management Platform",
        Subtitle:
          "Allows you to know in real time how much you have, where\n you have it and how it is.",
        First_Item: "Keep your stock updated from the field.",
        Second_Item:
          "Unify all the information of your silos in the same place.",
        Third_Item: "Make decisions remotely.",
      },
      BenefistTwo: {
        Title: "Quality & safety alerts.",
        Subtitle:
          "Get instant alerts of what's happening in the field, so you can take action as soon as possible.",
        First_Item: "Know what is happening inside the silo bag.",
        Second_Item: "Let the information of what is happening come to you.",
        Third_Item: "Avoid quality losses, theft and vandalism.",
      },
      BenefistThird: {
        Title: "More affordable insurance",
        Subtitle:
          "Allows you to insure stored grains against vandalism, theft, fire, hail and weather.",
        First_Item: "Insure assets that were previously uninsurable.",
        Second_Item: "More economical policies.",
        Third_Item: "Expansion of sub-limits.",
      },
      BenefistFourth: {
        Title: "Access to liquidity",
        Subtitle:
          "Access liquidity in different ways using your assets as collateral",
        First_Item: "Warrantee your grains at low cost.",
        Second_Item: "Tokenize your grains by accessing immediate liquidity.",
        Third_Item: "Expand your line of credit.",
      },
    },
    LandingLayerOfValuePage: {
      Access: "Access to Liquidity",
      Identity: "Identidad",
      Layer: "Layer of value",
      Quality: "Quality & Security",
      Traceability: "Traceability & Management",
    },
    LandingContactPage: {
      Commercial_Director: "Commercial Director",
      COO: "COO",
      Country_Manager: "Country Manager",
      Main_Title: "Contact our team",
      Support: "Support",
    },
    ProductPage: {
      Id: "ID",
      Metrix: "Metrix",
      Modal: "Coverage areas",
      Modal_First_Title: "Province of Buenos Aires",
      Modal_Second_Title: "North of Argentina",
      Real: "Real",
      Silo: "Silo",
      Silo_Id: {
        title:
          "Allows each silo bag to have its own identity,\n becoming unique, visible and traceable.",
        First_Item:
          "Documentation of silo bag assembly\n (proof of initial existence).",
        Second_Item:
          "Unique ID of each geo-referenced silo-bag with\n Field Merchandise Certificate.",
        Third_Item:
          "Digitization of the information at source to have\n the silo bag's useful life in one place.",
      },
      Silo_Metrix: {
        title:
          "Allows you to know how your grains are stored\n in the field and receive real-time alerts.",
        First_Item:
          "Real-time silo-bag telemetry with\n temperature, humidity, CO2 and movement sensors.",
        Second_Item:
          "Prevents loss of quality, theft and vandalism\n through instant alerts.",
        Third_Item:
          "Optimizes logistics management through TAS\n (safe storage time).",
      },
      Silo_View: {
        title:
          "Allows you to see the SiloID remotely and\n know that your silobag is in the field.",
        First_Item:
          "Recurring existence test of each SiloID\n without having to go to the field.",
        Second_Item: "SiloID monitoring through satellite images.",
        Third_Item:
          "Possibility of making the asset visible to third parties,\n providing access to security and liquidity solutions.",
      },
      Track: "Track",
      View: "View",
    },
    UsPage: {
      Main_Title_First: "A passionate ",
      Main_Title_Second: "multidisciplinary team ",
      Main_Title_Third: "that\n combines knowledge of ",
      Main_Title_Fourth:
        "agribusiness, technology,\n entrepreneurship and securitization.",
      First_Box_Title: "Who we are",
      First_Box_Content:
        "Identity On Field (IOF) is a Latin American ag-fin-tech\n startup that seeks to solve\n real world problems in a scalable way.",
      Second_Box_Title: "Mission",
      Second_Box_Content_One:
        "Our mission is to give digital identity to assets in the field to solve problems of traceability,\n security and access to liquidity. ",
      Second_Box_Content_Two:
        "Through technologies (IoT, satellite imagery and blockchain) we create a digital bridge that connects\n high-value field assets with an ecosystem of buyers, insurers, financial institutions, and others.",
      Third_Box_Title: "Meet some of our clients.",
      Third_Box_Content:
        "These companies give identity to their grains with SiloID.",
      Team: "Team",
    },
  },
};
export default en;
