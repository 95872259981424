import React, { memo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import BuenosAires from "../../assets/img/landing-product/buenos-aires.svg";
import NorteArgentino from "../../assets/img/landing-product/norte-argentino.svg";

import {
  ModalProps,
  NorthDataModalProps,
  NorthDataProps,
} from "../../shared/types";
import i18n from "../../translate/Language";

const prov = [
  "Gral. Arenales",
  "Leandro Alem",
  "Rivadavia",
  "Yrigoyen",
  "Daireaux",
  "Guamini",
  "Bolivar",
  "Pehuajo",
  "9 de Julio",
  "25 de Mayo",
  "Saladillo",
  "Chivilcoy",
  "Suipacha",
  "Pellegrini",
];

const north: NorthDataProps[] = [
  {
    title: "Salta",
    sub: [{ title: "Depto. de ANTA" }, { title: "Depto. de METAN" }],
  },
  {
    title: "Sgo. del Estero",
    sub: [
      { title: "Alberdi" },
      { title: "Moreno" },
      { title: "Juan F. Ibarra" },
    ],
  },
  {
    title: "Chaco",
    sub: [
      { title: "Almirante Brown" },
      { title: "Gral. Belgrano" },
      { title: "9 de Julio" },
      { title: "Chacabuco" },
    ],
  },
];

const NorthData = memo(({ item }: NorthDataModalProps) => (
  <Typography component={"div"} className="fs-14 fw-bold-600" sx={{ mb: 2 }}>
    {item.title}
    <hr className="divider-modal" />
    {item?.sub?.map((s, index: number) => (
      <Typography
        component={"div"}
        key={`north-sub-${index}`}
        className=" fw-bold-300 fs-12"
        sx={{ mb: 1 }}
      >
        {s?.title}
      </Typography>
    ))}
  </Typography>
));
const ProductModal = (props: ModalProps) => {
  const { open, handleClose, isMobile } = props;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={"custom-modal white-color"}>
        <Typography
          id="modal-modal-title"
          className="custom-modal-title"
          component={"div"}
          sx={{ fontWeight: 500 }}
        >
          <div className={`custom-product-tag ${isMobile ? "fs-16" : "fs-20"}`}>
            {i18n.t("app.ProductPage.Silo")}
            <span className={`orange-color`}>
              {i18n.t("app.ProductPage.Metrix")}
            </span>
          </div>
          <div className={"modal-subtitle fs-18 fw-bold-300"}>
            {`${i18n.t("app.ProductPage.Modal")}:`}
          </div>
        </Typography>
        <Typography
          component={"div"}
          id="modal-modal-description"
          sx={{ mt: { xs: 4, sm: 8 } }}
        >
          <Grid container columns={16} spacing={2}>
            <Grid
              item
              xs={16}
              md={4}
              lg={5}
              sx={{ textAlign: { xs: "-webkit-center" } }}
            >
              <Typography
                component={"div"}
                className="custom-product-prov-tag fs-14"
              >
                {i18n.t("app.ProductPage.Modal_First_Title")}
              </Typography>
              <img
                className={`modal-img-bs-as`}
                src={BuenosAires}
                alt={"bs-as"}
              />
            </Grid>
            {isMobile ? (
              <React.Fragment>
                <Grid item xs={8}>
                  {prov?.slice(0, 7).map((p, index: number) => (
                    <Typography
                      component={"div"}
                      key={`prov-f-${index}`}
                      className="fw-bold-300 fs-12"
                      sx={{ mt: 0.5 }}
                    >
                      {p}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={8}>
                  {prov?.slice(7).map((p, index: number) => (
                    <Typography
                      component={"div"}
                      key={`prov-s-${index}`}
                      className="fw-bold-300 fs-12"
                      sx={{ mt: 0.5 }}
                    >
                      {p}
                    </Typography>
                  ))}
                </Grid>
              </React.Fragment>
            ) : (
              <Grid item xs={16} md={4} lg={3}>
                {prov?.map((p, index: number) => (
                  <Typography
                    component={"div"}
                    key={`prov-${index}`}
                    className="fw-bold-300 fs-12"
                    sx={{ mt: 1.5 }}
                  >
                    {p}
                  </Typography>
                ))}
              </Grid>
            )}
            <Grid
              item
              xs={16}
              md={4}
              lg={5}
              sx={{ textAlign: { xs: "-webkit-center" } }}
            >
              <Typography
                component={"div"}
                className="custom-product-prov-tag fs-14"
              >
                {i18n.t("app.ProductPage.Modal_Second_Title")}
              </Typography>
              <img
                className={`modal-img-north`}
                src={NorteArgentino}
                alt={"north-ar"}
              />
            </Grid>

            {isMobile ? (
              <React.Fragment>
                <Grid item xs={8}>
                  {north?.slice(0, 2).map((n, index: number) => (
                    <React.Fragment key={`north-first-col-${index}`}>
                      <NorthData item={n} />
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={8}>
                  {north?.slice(2).map((n, index: number) => (
                    <React.Fragment key={`north-second-col-${index}`}>
                      <NorthData item={n} />
                    </React.Fragment>
                  ))}
                </Grid>
              </React.Fragment>
            ) : (
              <Grid item xs={16} md={4} lg={3}>
                {north?.map((n, index: number) => (
                  <React.Fragment key={`north-second-col-${index}`}>
                    <NorthData item={n} />
                  </React.Fragment>
                ))}
              </Grid>
            )}
          </Grid>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ProductModal;
