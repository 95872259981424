import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import ArgIcon from "../../../assets/img/landing-contact-page/arg.svg";
import FooterArgContact from "../../../assets/img/landing-contact-page/footerArgContact.svg";
import BrIcon from "../../../assets/img/landing-contact-page/br.svg";
import FooterBrContact from "../../../assets/img/landing-contact-page/footerBrContact.svg";
import FooterBrContactResponsive from "../../../assets/img/landing-contact-page/footerBrContactResponsive.svg";
import Instagram from "../../../assets/img/landing-contact-page/Instagram.svg";
import Linkedin from "../../../assets/img/landing-contact-page/Linkedin.svg";

import { LandingContactProps } from "../../../shared/types";

import "../../../assets/styles/landingStyles.css";
import i18n from "../../../translate/Language";

/**
 * Footer Contact section
 * @param props Props for LandingContactProps
 * @returns JSX.Element
 */
const FooterContactComponent = (props: LandingContactProps) => {
  const { className, id, isMobile, fromProduct } = props;

  const contact = [
    {
      title: i18n.t("app.LandingContactPage.Support"),
      subTitle: i18n.t("app.LandingContactPage.Support"),
      phone: "+54 9 11 2244-3491",
      mail: "info@iofcompany.com",
      icon: ArgIcon,
    },
    {
      title: "Martin L. Saubidet",
      subTitle: i18n.t("app.LandingContactPage.COO"),
      phone: "+54 9 11 3019-9000",
      mail: "mlsaubidet@iofcompany.com",
      icon: ArgIcon,
    },
    {
      title: "Matías Alberio",
      subTitle: i18n.t("app.LandingContactPage.Commercial_Director"),
      phone: "+54 9 11 2244-3491",
      mail: "malberio@iofcompany.com",
      icon: ArgIcon,
    },
    {
      title: "Jose F. Dominguez",
      subTitle: i18n.t("app.LandingContactPage.Country_Manager"),
      phone: "+54 9 11 4439-0120",
      mail: "jfdominguez@iofcompany.com",
      icon: BrIcon,
    },
  ];
  return (
    <Grid
      key={id}
      id={id}
      container
      className={`${className}  white-color`}
      columns={isMobile ? 0 : 16}
      sx={{ pt: isMobile ? (fromProduct ? 8 : 0) : 5, pb: { xs: 0, sm: 5 } }}
    >
      <Typography
        className={"white-color"}
        variant="h4"
        gutterBottom
        sx={{ fontWeight: 500, flexGrow: 1, textAlign: "center" }}
      >
        {i18n.t("app.LandingContactPage.Main_Title")}
      </Typography>
      <Grid
        container
        spacing={0}
        columns={16}
        alignItems="center"
        justifyContent="center"
        sx={{
          fontWeight: 600,
          textAlign: { xs: "center", sm: "left" },
          ml: { xs: 0, sm: 5 },
        }}
      >
        {contact?.map((m, index: number) => (
          <Grid
            item
            xs={16}
            sm={8}
            md={5}
            lg={4}
            key={index}
            sx={{ mb: { xs: index === 3 ? 0 : 2.5, sm: 0 } }}
          >
            <div>{m.title}</div>
            <hr className="main-color vector-img-2" />
            <div className="fw-bold-500 ">
              <span>
                {m.subTitle}{" "}
                <img
                  className={"contact-country-img"}
                  src={m.icon}
                  alt={"country-icon"}
                />
              </span>
            </div>
            <div className={" fw-bold-500 gray-color"}>{m.phone}</div>
            <div className={"fw-bold-500 gray-color"}>{m.mail}</div>
          </Grid>
        ))}
      </Grid>

      <Grid item xs={16} sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
        <hr />
      </Grid>

      <Grid
        item
        xs={16}
        sx={{
          mt: 2,
          display: isMobile ? "block" : "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="contact-container-f">
          <div className="contact-country">
            <img
              className="footer-contact-img"
              src={FooterArgContact}
              alt={"FooterArgContact-line"}
            />
            <div className={`footer-container ${isMobile ? "fs-12" : "fs-14"}`}>
              <span className="fw-bold-600">Quito 2618</span>
              {", San Isidro,\n Buenos Aires, Argentina."}
            </div>
          </div>
          <div className="contact-country">
            <img
              className="footer-contact-img"
              src={isMobile ? FooterBrContactResponsive : FooterBrContact}
              alt={"FooterBrContact-line"}
            />
            <div className={`footer-container ${isMobile ? "fs-12" : "fs-14"}`}>
              <span className="fw-bold-600">Rua Eng. Edgard Prado Arze.</span>
              {
                " Centro Politico Administrativo.\n Edifício FAMATO (Térreo), Cuiabá, Matogrosso"
              }
            </div>
          </div>
        </div>
        <div>
          <img
            className="footer-contact-img-social"
            src={Linkedin}
            alt={"Linkedin-line"}
            onClick={() =>
              window.open(
                "https://ar.linkedin.com/company/iofcompany",
                "_blank"
              )
            }
          />
          <img
            className="footer-contact-img-social"
            src={Instagram}
            alt={"Instagram-line"}
            onClick={() =>
              window.open(
                "https://instagram.com/iofcompany?igshid=MzRlODBiNWFlZA==",
                "_blank"
              )
            }
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default FooterContactComponent;
