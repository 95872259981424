import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Paths } from "../../config/paths";
import loadable from "@loadable/component";

import { LoadableLoadingComponent } from "../../components/base/loadableLoading";
import NoConnectionPage from "../../pages/noConnection/NoConnectionPage";
import ProductPage from "../../pages/product/ProductPage";
import UsPage from "../../pages/us/UsPage";

const fallback = {
  fallback: <LoadableLoadingComponent />,
};

const MainPage = loadable(() => import("../../pages/main/MainPage"), fallback);

const NotFoundPage = loadable(
  () => import("../../pages/notFound/NotFountPage"),
  fallback
);

const ProtectedRoute = loadable(() => import("./ProtectedRoute"), fallback);

export const RouterConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* PROTECTED LANDING ROUTES */}
        <Route
          path={Paths.ROOT}
          element={
            <ProtectedRoute path={Paths.ROOT}>
              <MainPage />
            </ProtectedRoute>
          }
        />

        {/* PROTECTED PRODUCT ROUTES */}
        <Route
          path={Paths.PRODUCT}
          element={
            <ProtectedRoute path={Paths.PRODUCT}>
              <ProductPage />
            </ProtectedRoute>
          }
        />

        {/* PROTECTED US ROUTES */}
        <Route
          path={Paths.US}
          element={
            <ProtectedRoute path={Paths.US}>
              <UsPage />
            </ProtectedRoute>
          }
        />

        {/* NON CONNECTION ROUTE */}
        <Route
          path={Paths.NO_CONNECTION}
          element={
            <ProtectedRoute path={Paths.NO_CONNECTION}>
              <NoConnectionPage />
            </ProtectedRoute>
          }
        />

        {/* NOT FOUND ROUTE */}
        <Route
          path={Paths.NOT_FOUND}
          element={
            <ProtectedRoute path={Paths.NOT_FOUND}>
              <NotFoundPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
