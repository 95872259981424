import React, { memo } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import ClientOne from "../../assets/img/landing-us/client-one.svg";
import ClientTwo from "../../assets/img/landing-us/client-two.svg";
import ClientThree from "../../assets/img/landing-us/client-three.svg";
import ClientFour from "../../assets/img/landing-us/client-four.svg";
import ClientFive from "../../assets/img/landing-us/client-five.svg";
import ClientSix from "../../assets/img/landing-us/client-six.svg";
import ClientSeven from "../../assets/img/landing-us/client-seven.svg";
import ClientEight from "../../assets/img/landing-us/client-eight.svg";
import ClientNine from "../../assets/img/landing-us/client-nine.svg";
import ClientTen from "../../assets/img/landing-us/client-ten.svg";
import ClientEleven from "../../assets/img/landing-us/client-eleven.svg";
import ClientTwelve from "../../assets/img/landing-us/client-twelve.svg";
import ClientThirteen from "../../assets/img/landing-us/client-thirteen.svg";
import ClientFifteen from "../../assets/img/landing-us/client-fifteen.svg";
import ClientSixteen from "../../assets/img/landing-us/client-sixteen.svg";
import ClientSeventeen from "../../assets/img/landing-us/client-seventeen.svg";
import ClientEighteen from "../../assets/img/landing-us/client-eighteen.svg";
import ClientNineteen from "../../assets/img/landing-us/client-nineteen.svg";
import ClientTwenty from "../../assets/img/landing-us/client-twenty.svg";

import { UsSectionProps } from "../../shared/types";

import "../../assets/styles/landingStyles.css";
import i18n from "../../translate/Language";
import useImagePreloader from "../../hooks/useImagePreloader";

import { Card, CardContent } from "@mui/material";

const Clients = [
  ClientOne,
  ClientTwo,
  ClientThree,
  ClientFour,
  ClientFive,
  ClientSix,
  ClientSeven,
  ClientEight,
  ClientNine,
  ClientTen,
  ClientEleven,
  ClientTwelve,
  ClientThirteen,
  ClientFifteen,
  ClientSixteen,
  ClientSeventeen,
  ClientEighteen,
  ClientNineteen,
  ClientTwenty,
];

const preloadSrcList: string[] = Clients;

const IconSection = memo(({ img, index }: any) => {
  return (
    <Grid item xs={6} lg={2} className="client-from-us-page">
      <img className="client-logo" src={img} alt={`client-icon-nro-${index}`} />
    </Grid>
  );
});
/**
 * Us section
 * @param props Props for UsSectionProps
 * @returns JSX.Element
 */
const UsSection = (props: UsSectionProps) => {
  const { className, id, isMobile } = props;
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  const boxes = [
    {
      title: i18n.t("app.UsPage.First_Box_Title"),
      lg: 5.8,
      content: [{ text: i18n.t("app.UsPage.First_Box_Content") }],
    },
    {
      title: i18n.t("app.UsPage.Second_Box_Title"),
      lg: 9,
      content: [
        { text: i18n.t("app.UsPage.Second_Box_Content_One") },
        { text: i18n.t("app.UsPage.Second_Box_Content_Two") },
      ],
    },
  ];

  return (
    <Grid
      key={id}
      container
      columns={isMobile ? 0 : 16}
      textAlign={isMobile ? "left" : "center"}
      className={`${className} first-landing white-color-2`}
      sx={{ pb: 5 }}
    >
      <Grid className="us-page-container fw-bold-400" item xs={16}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            flexGrow: 1,
            textAlign: "center",
            pt: 5,
            pb: 5,
            whiteSpace: "pre-line",
          }}
        >
          {i18n.t("app.UsPage.Main_Title_First")}
          <span className="main-color fw-bold-500">
            {i18n.t("app.UsPage.Main_Title_Second")}
          </span>{" "}
          {i18n.t("app.UsPage.Main_Title_Third")}
          <span className="fw-bold-500 white-color">
            {" "}
            {i18n.t("app.UsPage.Main_Title_Fourth")}
          </span>
        </Typography>
      </Grid>

      <Grid
        container
        columns={15}
        direction="row"
        justifyContent="center"
        alignItems="center"
        textAlign={"left"}
        className="us-page-container"
        sx={{ mt: { xs: 4 } }}
      >
        {boxes?.map((b, index: number) => (
          <Grid
            key={`box-content-$
            ${index}`}
            item
            component={Card}
            xs={16}
            lg={b.lg}
            className="card-us-page white-color "
            sx={{ ml: index > 0 ? "auto" : "" }}
          >
            <CardContent className={"card-element"} sx={{ padding: 4 }}>
              <Typography className={"fw-bold-600 fs-20"} gutterBottom>
                {b?.title}
              </Typography>
              {b?.content?.map((c, index: number) => (
                <Typography
                  key={`content-box-${index}`}
                  className={"fw-bold-400 fs-16"}
                  component={"div"}
                  gutterBottom
                  sx={{ mt: index > 0 ? 2 : "" }}
                >
                  {c?.text}
                </Typography>
              ))}
            </CardContent>
          </Grid>
        ))}

        <Grid
          item
          component={Card}
          xs={16}
          className="card-us-page-client white-color "
        >
          <CardContent sx={{ padding: 4.2 }}>
            <Typography className={"fw-bold-600 fs-20"} gutterBottom>
              {i18n.t("app.UsPage.Third_Box_Title")}
            </Typography>
            <Typography
              className={"fw-bold-400 fs-16"}
              component={"div"}
              gutterBottom
              sx={{ mt: 2 }}
            >
              {i18n.t("app.UsPage.Third_Box_Content")}
            </Typography>

            {isMobile ? (
              <Grid
                container
                columns={15}
                justifyContent="space-between"
                sx={{ gap: 1, mt: 5.2, mb: 5.2 }}
              >
                {Clients?.map((c, index: number) => (
                  <IconSection key={`client-${index}`} img={c} index={index} />
                ))}
              </Grid>
            ) : (
              <React.Fragment>
                <Grid
                  container
                  columns={15}
                  justifyContent="space-between"
                  sx={{ gap: 1, mt: 5.2, mb: 5.2 }}
                >
                  {Clients?.slice(0, 7).map((c, index: number) => (
                    <IconSection
                      key={`client-${index}`}
                      img={c}
                      index={index}
                    />
                  ))}
                </Grid>
                <Grid
                  container
                  columns={15}
                  justifyContent="space-between"
                  sx={{ gap: 1, mt: 5.2, mb: 5.2 }}
                >
                  {Clients?.slice(7, 13).map((c, index: number) => (
                    <IconSection
                      key={`client-${index}`}
                      img={c}
                      index={index}
                    />
                  ))}
                </Grid>
                <Grid
                  container
                  columns={15}
                  justifyContent="space-between"
                  sx={{ gap: 1, mt: 5.2, mb: 5.2 }}
                >
                  {Clients?.slice(13).map((c, index: number) => (
                    <IconSection
                      key={`client-${index}`}
                      img={c}
                      index={index}
                    />
                  ))}
                </Grid>
              </React.Fragment>
            )}
          </CardContent>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UsSection;
